import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/angular-controllers-share-state",
  "date": "2014-06-28",
  "title": "ANGULAR CONTROLLERS SHARE STATE",
  "author": "admin",
  "tags": ["development", "javascript", "angular.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "When your Angular.js application expands to a state that you no longer find it easy to understand, it's time you used dependency injection and modularise your application into units of interrelated modules."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`AngularJS makes you write code with testability, dependency injection, and modularity. You didn't need to create a class to contain that piece of conversion logic, you needed a filter. But there will be situations where one controller needs to talk to another controller. This is a result of keeping all dependencies handled by a Dependency Injection framework. When your controller has to pass state to another controller, that's where you need a service.`}</p>
    <h2>{`State Service`}</h2>
    <p>{`A novice Angular developer would come with a solution like the following.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`angular.module('services', [])
  .factory('state', function () {
     state;

    return {
      state: state,
    };
  });
`}</code></pre>
    <p>{`What's the issue with this solution? In many cases, you may not even notice it. The answer is `}<strong parentName="p">{`Objects and arrays are passed by reference. Values are not.`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var state = { name: "one" };
var controller = { name: state.name };
state. = "two";
controller.name; // "one"
`}</code></pre>
    <p>{`So updating the name property of this `}<strong parentName="p">{`state service`}</strong>{` does not propagate changes to the scope of each controller. If your state happens to be an array or an object, and you stick to modifying it rather than reassigning it, this would still work (it is possible to implement the `}<strong parentName="p">{`state service`}</strong>{` using a shared object instead, I’ve added an example of this at the bottom of the page). However, AngularJS’s $scope is a bit more complicated than my silly example, and has some inner machinery that will not react as you expect. You see, in order to keep the DOM and the data on the scope in sync, also known as two way binding, it uses something called the `}<a style={{
        "fontWeight": "inherit",
        "fontStyle": "inherit",
        "color": "#1f0909"
      }} href="http://docs.angularjs.org/guide/concepts" target="_blank">{`digest cycle`}</a>{`. A new digest cycle is only triggered if AngularJS can recognize a change being made on the scope. Since changes are made far, far away, it will be unable to do so. Bummer!`}</p>
    <h2>{`Events`}</h2>
    <p>{`Luckily, we have events. In AngularJS, these are transmitted through the rootScope and bubble up through all scopes.
Using `}<inlineCode parentName="p">{`$scope.$on`}</inlineCode>{` we can attach listeners to these and react to changes in our state service!
So lets try again. In steps, what we want to do is this:`}</p>
    <ul>
      <li parentName="ul">{`Set an initial state and expose this`}</li>
      <li parentName="ul">{`Expose an updating function`}</li>
      <li parentName="ul">{`Let this function broadcast changes`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`angular.module('services', [])
  .factory('state', function ($rootScope) {
    'use strict';

     state;

     broadcast = function (state) {
      $rootScope.$broadcast('state.update', state);
    };

     update = function (newState) {
      state = newState;
      broadcast(state);
    };
    
    return {
      update: update,
      state: state,
    };
  });
`}</code></pre>
    <p>{`And on the controller side:`}</p>
    <ul>
      <li parentName="ul">{`Get the initial state`}</li>
      <li parentName="ul">{`Set up a listener on the change event`}</li>
      <li parentName="ul">{`Update the state when needed`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`angular.module('controllers', ['services'])
  .factory('MainCtrl', function ($scope, state) {
    $scope.state = state.state;
    
    $scope.('state.update', function (newState) {
      $scope.state = newState;
    });
    
    $scope.update = state.update;
  });
`}</code></pre>
    <p>{`As a convenience, we could move the boilerplate of event attaching to the state manager. Something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// in the service
 onUpdate = function ($scope, callback) {
  $scope.('state.update', function (newState) {
    callback(newState);
  });
};

// and in the controller
state.onUpdate($scope, function (newState) {
  $scope.state = newState;
};
`}</code></pre>
    <h2>{`Finishing up`}</h2>
    <p>{`Now you can share state between controllers in AngularJS. You can easily manage the logged-in user, the user settings, or handle navigation with one controller, and the page content with another!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`angular.module('services', [])
  .factory('state', function () {
    'use strict';

     state = {};
    
    return {
      state: state,
    };
  });
`}</code></pre>
    <p>{`Using it our controller looks more like this.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`angular.module('controllers', ['services'])
  .factory('MainCtrl', function ($scope, state) {
    $scope.state = state.state;
  });
`}</code></pre>
    <p>{`The `}<strong parentName="p">{`big`}</strong>{` difference here will be in the markup, where you refer to your state, eg a property called username, as `}<inlineCode parentName="p">{`state.username`}</inlineCode>{` rather than just `}<inlineCode parentName="p">{`username`}</inlineCode>{`.
In most cases this is plenty! If you need to react to changes in the state object, you can `}<inlineCode parentName="p">{`$watch`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`angular.module('controllers', ['services'])
  .factory('MainCtrl', function ($scope, state) {
    $scope.state = state.state;

    $scope.$watch('state', function (newVal, oldVal) {
      // your code here
    });
  });
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      